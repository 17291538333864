import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@/components/Common/Modal';
import InitialInputScreen from './components/InitialInputScreen';
import IncrementProductPersonalizationScreen from './components/IncrementProductPersonalizationScreen';
import { closeProductPersonalizationPopUp } from '../../redux/actions';
import { getHeadingText } from './utils/common';
import { IS_CLIENT } from '@/utils/checkRenderEnv';
import { CancelIcon } from './utils/SvgExport';
import { useCartHandlers } from '@/hooks/useCartHandler';

export const ProductCustomizationPopUp = (props) => {
  const dispatch = useDispatch();
  const {
    productData,
    productCustomizationCount,
    personalizedProduct,
    storeInfo,
    cartData,
  } = useSelector((state) => ({
    productData: state.catalogReducer?.personalizedProduct?.productData,
    productCustomizationCount:
      state.catalogReducer?.personalizedProduct?.productCustomizationCount,
    personalizedProduct: state.catalogReducer?.personalizedProduct,
    storeInfo: state.storeReducer.store,
    cartData: state.cartReducer,
  }));

  const {
    imageCount = 0,
    textCount = 0,
    allImageMandatory = false,
  } = productCustomizationCount || {};

  const [images, setImages] = useState(Array.from({ length: imageCount }, () => null));
  const [tempImages, setTempImages] = useState(
    Array.from({ length: imageCount }, () => null)
  );
  const [textData, setTextData] = useState(['']); // Initialize as an array with one empty string
  const [errorState, setErrorState] = useState('');

  // Usecase : To keep track of updated quantity and personalisations in popup, before user clicks on confirm changes.
  const [personalizationLocalState, setPersonalizationLocalState] = useState(
    personalizedProduct?.personalizationData?.personalization_info?.personalizations ?? []
  );

  const [product, setProduct] = useState(productData);

  const { handleCart, onBuyNowClick } = useCartHandlers();

  useEffect(() => {
    const newTextData = personalizedProduct?.editableData?.texts ?? [''];
    const filledImages = new Array(imageCount)
      .fill(null)
      .map((_, i) => personalizedProduct?.editableData?.images?.[i] || null);
    setImages(filledImages);
    setTempImages(filledImages);
    setTextData(newTextData);
    setProduct(productData);
  }, [
    personalizedProduct?.editableData,
    productData,
    personalizedProduct?.personalizationPopUp,
  ]);

  useEffect(() => {
    setPersonalizationLocalState(() => {
      return (
        personalizedProduct?.personalizationData?.personalization_info
          ?.personalizations || []
      );
    });
  }, [personalizedProduct?.personalizationData]);

  const handleModalClose = () => {
    IS_CLIENT && localStorage.removeItem('activeVariantId');
    dispatch(closeProductPersonalizationPopUp());
  };

  const showInitialScreen = () => personalizedProduct?.showInitialInputScreen;

  const handleAddPersonalizationToLocalState = (data) => {
    setPersonalizationLocalState((prevLocalState) => [...prevLocalState, data]);
  };

  return (
    <Modal
      visible={personalizedProduct?.personalizationPopUp}
      className={`bottom-modal tw-z-[10000] ${showInitialScreen() ? 'product-customization-modal-initial ' : 'product-customization-modal-card '}`}
      animation={'slideUp'}
      onClose={handleModalClose}
    >
      <div className={`tw-h-full tw-w-full md:tw-px-[0.75rem] md:tw-py-[0.75rem]`}>
        <div
          className={`tw-relative tw-flex tw-flex-col ${showInitialScreen() ? 'tw-gap-[2rem]' : 'tw-gap-[1.5rem]'} `}
        >
          <p className="tw-m-[0px] tw-text-[1rem] tw-font-bold tw-text-[#000] md:tw-text-[1.5rem]">
            {getHeadingText(showInitialScreen, personalizedProduct)}
          </p>
          {showInitialScreen() ? (
            <InitialInputScreen
              imageCount={imageCount}
              textCount={textCount}
              images={images}
              tempImages={tempImages}
              setTempImages={setTempImages}
              setImages={setImages}
              textData={textData}
              setTextData={setTextData}
              errorState={errorState}
              setErrorState={setErrorState}
              storeInfo={storeInfo}
              personalizedProduct={personalizedProduct}
              handleCart={handleCart}
              productData={product}
              source={''}
              allImageMandatory={allImageMandatory}
              onBuyNowClick={onBuyNowClick}
              cartData={cartData}
              handleAddPersonalizationToLocalState={handleAddPersonalizationToLocalState}
              personalizationLocalState={personalizationLocalState}
            />
          ) : (
            <IncrementProductPersonalizationScreen
              personalizedProduct={personalizedProduct}
              storeData={storeInfo}
              cartData={cartData}
              setImages={setImages}
              setTempImages={setTempImages}
              setTextData={setTextData}
              imageCount={imageCount}
              handleCart={handleCart}
              productData={product}
              source={''}
              personalizationLocalState={personalizationLocalState}
              setPersonalizationLocalState={setPersonalizationLocalState}
            />
          )}
          <span
            className="tw-absolute tw-right-[-8px] tw-top-[-0.7rem] tw-cursor-pointer md:tw-right-[-16px] md:tw-top-[-1rem]"
            onClick={handleModalClose}
          >
            <CancelIcon />
          </span>
        </div>
      </div>
    </Modal>
  );
};
