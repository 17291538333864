import React from 'react';
import { useSelector } from 'react-redux';
import interLoader from '../../../public/assets/images/inter-loader.gif';

export function FullPageLoader() {
  const { globalLoader } = useSelector((state) => ({
    globalLoader: state.commonReducer.globalLoader,
  }));

  return (
    globalLoader && (
      <div className="fullpage-global-loader">
        <div>
          <img src={interLoader.src} />
        </div>
      </div>
    )
  );
}
